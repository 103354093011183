require('./DynamicModalHandler.js');

(function(wnd){
  function SiteEventHandler(params) {
    try {
      if (params) {
        this.setParams(params);
      }

      this.init();
    } catch (error) {
      console.error(error);
    }
  }

  SiteEventHandler.prototype = {
    selectors: {
      cartButton:'[data-purpose="add-to-cart"]',
      favouritesButton: '[data-purpose="add-to-favourites"]',
      cartCounter:'[data-purpose="cart-counter"]',
      headerCartLink:'[data-purpose="header-cart-link"]',
      favouritesCounter:'[data-purpose="favourites-counter"]',
      headerFavouritesLink:'[data-purpose="header-favourites-link"]',
    },
    attributeNames: {
      productId:'data-product-id'
    },
    options: {
      disableEmptyCartClick: true,
    },
    customDOMEvents: function() {},
    init: function() {
      this.addDOMEvents();
      this.addDOMEventListeners();

      if (typeof this.customDOMEvents === 'function') {
        this.customDOMEvents();
      }
    },
    addDOMEvents: function() {
      var _self = this;

      $(this.getSelector('cartButton')).on('click', function(e){
        e.preventDefault();

        $(document).trigger(PROJECT_NAMESPACE+'.add_to_cart', {
          id: $(this).attr(_self.getAttributeName('productId')),
          quantity: 1
        });
      });

      $(this.getSelector('favouritesButton')).on('click', function(e){
        e.preventDefault();

        $(document).trigger(PROJECT_NAMESPACE+'.add_to_favourites', $(this));
      });

      if (this.getOption('disableEmptyCartClick')) {
        $(this.getSelector('headerCartLink')).click(function(e){
          if ($(this).hasClass('empty')) {
            e.preventDefault();
          }
        });
      }

      $(this.getSelector('headerFavouritesLink')).on('click', function(){
        $(document).trigger(PROJECT_NAMESPACE+'.open_favourites_modal');
      });
    },
    addDOMEventListeners: function() {
      var _self = this;

      $(document).on(PROJECT_NAMESPACE+'.cart_count_changed', function(e, data){
        _self.handleCartCountChanged(data);
      });

      $(document).on(PROJECT_NAMESPACE+'.favourites_count_changed', function(e, data){
        _self.handleFavouritesCountChanged(data);
      });

      $(document).on(PROJECT_NAMESPACE+'.handle_form_errors', function(evt, data) {
        _self.handleFormErrors(data.form, data.errors);
      });

      $(document).on(PROJECT_NAMESPACE+'.open_dynamic_modal', function(evt, data) {
        _self.handleDynamicModalOpen(data);
      });
    },
    setParams: function(params) {
      if (params.selectors) this.setSelector(params.selectors);
      if (params.attributeNames) this.setAttributeNames(params.attributeNames);
      if (params.options) this.setOptions(params.options);

      if (typeof params.customDOMEvents === 'function') {
        this.customDOMEvents = params.customDOMEvents;
      }
    },
    setSelector: function(selectors) {
      this.selectors = $.extend({}, this.selectors, selectors);
    },
    setAttributeNames: function(attributeNames) {
      this.attributeNames = $.extend({}, this.attributeNames, attributeNames);
    },
    setOptions: function(options) {
      this.options = $.extend({}, this.options, options);
    },
    getSelector: function(key) {
      return this.getObjectValue('selectors', key);
    },
    getAttributeName: function(key) {
      return this.getObjectValue('attributeNames', key);
    },
    getOption: function(key) {
      return this.getObjectValue('options', key);
    },
    getObjectValue: function(object, key) {
      if (typeof this[object] === 'undefined') {
        throw 'Undefined property '+object+'!'
      }

      if (typeof this[object][key] === 'undefined') {
        throw 'Undefined '+object+' key: '+key+'!'
      }

      return this[object][key];
    },
    handleCartCountChanged: function(data) {
      if (typeof data.count !== 'undefined') {
        this.handleCountChanged('cart', data.count);
      }
    },
    handleFavouritesCountChanged: function (data) {
      if (typeof data.count !== 'undefined') {
        if (typeof data.item !== 'undefined' && typeof data.itemParams === 'object') {
          var item = $(data.item);
          var params = data.itemParams;
          var isFavourite = typeof params.isFavourite !== 'undefined' ? params.isFavourite : true;
          var count = typeof params.itemCount !== 'undefined' ? parseInt(params.itemCount) : 0;
          if (isNaN(count)) {
            count = 0;
          }

          item.data('counter', count);
          item.find('.text').html(count);

          if (!isFavourite) {
            item.removeClass('is-favourite');
          } else {
            item.addClass('is-favourite');
          }

          if (count <= 0 && !item.hasClass('empty')) {
            item.addClass('empty');
          } else if (count > 0 && item.hasClass('empty')) {
            item.removeClass('empty');
          }
        }

        this.handleCountChanged('favourites', data.count);
      }
    },
    handleCountChanged: function(type, count) {
      var linkSelector = this.getSelector('header'+(type.charAt(0).toUpperCase()+type.slice(1))+'Link');
      var counterSelector = this.getSelector(type+'Counter');

      if (linkSelector && counterSelector) {
        if (count <= 0) {
          count = 0;
        }
        $(counterSelector).html(count);

        var headerLink = $(linkSelector);
        if (headerLink && headerLink.length > 0) {
          if (count > 0 && headerLink.hasClass('empty')) {
            headerLink.removeClass('empty');
          } else if (count <= 0 && !headerLink.hasClass('empty')) {
            headerLink.addClass('empty');
          }
        }
      }
    },
    handleFormErrors: function(form, errors) {
      if (typeof errors === 'string') {
        this.showSystemMessage(errors, 'error');
      } else if (typeof errors === 'object') {
        var _self = this;
        var showedMessages = [];

        $.each(errors, function(group, values){
          var input = form.find('[name="'+group+'"]');
          if (typeof values === 'string') {
            if (values.trim() !== '' && showedMessages.indexOf(values) < 0) {
              showedMessages.push(values);
              _self.showSystemMessage(values, 'error');
            }

            if (input && input.length > 0) {
              input.addClass("has-error");

              var id = input.attr('id');
              if (id && !input.is(':radio') && !input.is(':checkbox')) {
                var label = $('label[for="'+id+'"]');
                if (label && label.length === 1) {
                  label.addClass('has-error');
                }
              }
            }
          } else if (typeof values === 'object') {
            $.each(values, function(index, value) {
              if (typeof value === 'string' && value.trim() !== '') {
                if (showedMessages.indexOf(value) < 0) {
                  showedMessages.push(value);
                  _self.showSystemMessage(value, 'error');
                }
              }

              var input = form.find('[name="'+group+'['+index+']"]');
              if (input && input.length > 0) {
                input.addClass('has-error');
                var id = input.attr('id');
                if (id && !input.is(':radio') && !input.is(':checkbox')) {
                  var label = $('label[for="'+id+'"]');
                  if (label && label.length === 1) {
                    label.addClass('has-error');
                  }
                }
              }
            });
          }
        });
      }
    },
    handleDynamicModalOpen: function(data) {
      new DynamicModalHandler(data);
    },
    showSystemMessage: function(message, type) {
      if (!type) {
        type = 'success';
      }
      if (message.trim() == '') {
        return;
      }

      $(document).trigger(PROJECT_NAMESPACE+'.show_system_message', {
        type: type,
        message: message
      });
    }
  }

  wnd.SiteEventHandler = SiteEventHandler;
})(window);
