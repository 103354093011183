require('./core/jquery.elevatezoom.js');

(function (wnd) {
  var randomGalleryNumbers = [];

  function ProductGalleryWindow(parent) {
    try {
      this.parent = parent;

      this.init();
    } catch (error) {
      console.error(error);
    }
  }

  ProductGalleryWindow.prototype = {
    container: undefined,
    galleryContainer: undefined,
    overlay: undefined,
    parent: undefined,
    thumbnailContainer: undefined,
    sliderContainer: undefined,
    sliderList: undefined,
    imageList: undefined,
    slider: undefined,
    closeBtn: undefined,
    singleImageGallery: true,
    pixelRatio: 1,
    init: function () {
      this.setElements();

      if (!this.singleImageGallery) {
        this.createThumbnailList();
      }
      this.createSliderContent();
      this.addDOMEvents();
    },
    setElements: function () {
      this.container = $('<div>', {
        class: 'product-gallery-window-container',
      });
      this.overlay = $('<div>', {
        class: 'product-gallery-overlay',
      });

      this.galleryContainer = $('<div>', {
        class: 'product-gallery-window'
      });

      this.sliderContainer = $('<div>', {
        class: 'product-gallery-slider-container swiper-container'
      });

      this.pixelRatio = window.devicePixelRatio;

      if (this.parent.images.length > 1) {
        this.singleImageGallery = false;

        this.thumbnailContainer = $('<div>', {
          class: 'thumbnail-container'
        });
        this.galleryContainer.append(this.thumbnailContainer);
      } else {
        this.sliderContainer.addClass('single-image-gallery');
      }

      this.closeBtn = $('<span>', {
        class: 'close-btn cursor-pointer',
        html: svg('close-icon')
      });

      this.galleryContainer.append(this.closeBtn);
      this.galleryContainer.append(this.sliderContainer);

      this.container.append(this.overlay);
      this.container.append(this.galleryContainer);

      $('body').append(this.container);
    },
    addDOMEvents: function () {
      var _self = this;

      this.overlay.click(function () {
        _self.close();
      });

      if (!this.singleImageGallery) {
        this.thumbnailContainer.find('.thumbnail-item').click(function () {
          _self.slider.slideTo($(this).data('index'));
        });
      }

      if (this.closeBtn && this.closeBtn.length > 0) {
        this.closeBtn.click(function () {
          _self.close();
        });
      }
    },
    open: function () {
      $('body').addClass('overflow-hidden opened-product-gallery');
      $('html').addClass('overflow-hidden');

      if (!this.singleImageGallery) {
        this.initSlider()
      }
    },
    close: function () {
      $('body').removeClass('overflow-hidden opened-product-gallery');
      $('html').removeClass('overflow-hidden');
      this.stopVideos();
      this.parent.initPreviewZoom();
      if (!this.singleImageGallery) {
        this.destroySlider();
      }
    },
    stopVideos: function () {
      this.sliderContainer.find('video').each(function () {
        $(this).trigger('pause');
      });
    },
    initSlider: function () {
      var _self = this;
      this.slider = new Swiper('.product-gallery-slider-container', {
        initialSlide: this.parent.currentIndex,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      });
      this.markActiveThumbItem(this.parent.currentIndex);
      this.slider.on('transitionEnd', function () {
        _self.stopVideos();
        var item = _self.sliderContainer.find('.swiper-slide-active');
        if (item) {
          var index = $(item).data('index');
          _self.markActiveThumbItem(index);
        }
      });
    },
    markActiveThumbItem: function (index) {
      this.thumbnailContainer.find('.thumbnail-item').removeClass('active');
      this.thumbnailContainer.find('.window-thumbnail-item[data-index="' + index + '"]').addClass('active');
    },
    destroySlider: function () {
      this.slider.destroy();
    },
    createSliderContent: function () {
      this.sliderList = $('<ul>', {
        class: 'product-gallery-slider-list swiper-wrapper without-default-style',
      });

      for (var i = 0; i < this.parent.images.length; i++) {
        var img = this.parent.images[i];
        var item = $('<li>', {
          class: 'product-gallery-slider-item swiper-slide display-flex justify-center align-items-center',
          'data-index': i
        });

        if (typeof img.is_video !== 'undefined' && img.is_video) {
          item.append(this.parent.getVideoContent(img));
        } else {
          var src = img.urls.big;
          if (this.parent.isRetina() && typeof img.retinaUrls !== 'undefined' && typeof img.retinaUrls.big !== 'undefined' && img.retinaUrls.big) {
            src = img.retinaUrls.big;
          }
          item.append('<img src="' + src + '" alt="' + img.name + '" />');
        }

        this.sliderList.append(item);
      }

      this.sliderContainer.append(this.sliderList);
      if (this.parent.images.length > 1) {
        this.sliderContainer.append('<div class="swiper-pagination display-flex justify-center align-items-center zero-text"></div>');
      }
    },
    createThumbnailList: function () {
      for (var i = 0; i < this.parent.images.length; i++) {
        var img = this.parent.images[i];
        var item = $('<div>', {
          class: 'thumbnail-item window-thumbnail-item display-inline-flex justify-center align-items-center cursor-pointer position-relative' + (i == this.parent.currentIndex ? ' active' : ''),
          'data-index': i
        });

        if (typeof img.is_video !== 'undefined' && img.is_video) {
          var poster = img.urls.small;
          if (this.parent.isRetina() && typeof img.retinaUrls !== 'undefined' && typeof img.retinaUrls.small !== 'undefined' && img.retinaUrls.small) {
            poster = img.retinaUrls.small;
          }

          if (poster) {
            item.append('<img src="' + poster + '" alt="' + img.name + '" />');
          }

          item.append('<span class="play-button display-flex justify-center align-items-center cursor-pointer position-absolute absolute-center">' + svg('play-icon') + '</span>');
        } else {
          var src = typeof img.urls.small !== 'undefined' ? img.urls.small : img.urls.big;
          if (this.parent.isRetina() && typeof img.retinaUrls !== 'undefined') {
            if (typeof img.retinaUrls.small !== 'undefined' && img.retinaUrls.small) {
              src = img.retinaUrls.small;
            } else if (typeof img.retinaUrls.big !== 'undefined' && img.retinaUrls.big) {
              src = img.retinaUrls.big;
            }
          }
          item.append('<img src="' + src + '" alt="' + img.name + '" />');
        }

        this.thumbnailContainer.append(item);
      }
    }
  };

  function ProductGallery(container, params) {
    try {
      if (!container || (container && container.length == 0)) {
        throw 'Container is required!';
      }

      this.container = container;
      if (params) {
        this.setParams(params);
      }
      this.init();
    } catch (error) {
      console.error(error);
    }
  }

  ProductGallery.prototype = {
    container: undefined,
    previewContainer: undefined,
    thumbnailContainer: undefined,
    galleryList: undefined,
    gallerySlider: undefined,
    thumbnailList: undefined,
    thumbnailSlider: undefined,
    thumbnailPrevButton: undefined,
    thumbnailNextButton: undefined,
    currentIndex: 0,
    windowWidth: 0,
    images: [],
    options: {
      breakpoint: 600,
      logMode: false,
      thumbnailDesktopLimit: 0,
      hasThumbnailPager: true,
      thumbnailPagerButtonIcon: 'arrow-down',
      thumbnailListType: 'vertical',
      thumbnailPagerBreakpoint: 1023,
      galleryWindowAvailable: true,
      popupMode: false,
      zoomEnabled: false,
      videoOptions: {
        controls: true
      },
      zoomOptions: {
        scrollZoom: true,
        borderColour: '#BF2A26',
        borderSize:1,
        lensBorderColour: '#BF2A26',
        lensColour: '#FFECEC',
        lensOpacity: 0.3
      }
    },
    opened: false,
    currentIsVideo: false,
    galleryWindow: undefined,
    overlay: undefined,
    mode: undefined,
    initialized: false,
    pixelRatio: 1,
    generatedId: undefined,
    selectors: {
      previewContainer: '[data-purpose="preview-container"]',
      thumbnailContainer: '[data-purpose="thumbnail-container"]',
      thumbnailList: '[data-purpose="thumbnail-list"]'
    },
    init: function () {
      this.windowWidth = window.innerWidth;
      this.pixelRatio = window.devicePixelRatio;

      if (!this.generatedId) {
        this.generateRandomNumber();
      }

      this.setElements();
      this.addDOMEvents();
      this.checkThumbnailSlider();

      this.currentIsVideo = this.previewContainer.find('video').length > 0;
      if (!this.currentIsVideo && this.getOption('zoomEnabled')) {
        this.initPreviewZoom();
      }

      this.initialized = true;

      if (typeof window.lazyLoadInstance !== 'undefined') {
        window.lazyLoadInstance.update();
      }
    },
    generateRandomNumber: function() {
      var limit = 0;
      do {
        rand = Math.random();
        number = parseInt(rand * 8999 + 1000);

        limit++;
        if (limit >= 100) {
          break;
        }
      } while(randomGalleryNumbers.indexOf(number) >= 0);
      randomGalleryNumbers.push(number);

      this.generatedId = number;
    },
    setElements: function () {
      this.previewContainer = this.container.find(this.getSelector('previewContainer'));
      this.thumbnailContainer = this.container.find(this.getSelector('thumbnailContainer'));
      this.thumbnailList = this.container.find(this.getSelector('thumbnailList'));

      this.thumbnailContainer.addClass('thumbnail-container-'+this.generatedId);
    },
    addDOMEvents: function () {
      var _self = this;

      if (this.previewContainer && this.previewContainer.length == 1) {
        this.previewContainer.click(function () {
          _self.openGallery();
        });
      }

      $(document).on(PROJECT_NAMESPACE+'.window_width_changed', function (e, newWidth) {
        _self.windowWidth = newWidth;
        _self.checkThumbnailSlider();

        if (_self.getOption('hasThumbnailPager')) {
          _self.slideThumbnails(0);
        }
      });
    },
    addThumbnailDOMEvents: function () {
      var _self = this;

      this.thumbnailList.find('.thumbnail-item').click(function () {
        _self.thumbnailList.find('.thumbnail-item').removeClass('active');
        $(this).addClass('active');
        var index = $(this).data('index');

        if ($(this).hasClass('more-label-item')) {
          _self.currentIndex = parseInt(index);
          _self.openGallery();
        } else {
          _self.showPreview($(this).data('index'));
        }
      });

      if (this.getOption('hasThumbnailPager')) {
        this.thumbnailNextButton.click(function () {
          if (!$(this).hasClass('inactive')) {
            _self.slideThumbnails(-1);
          }
        });

        this.thumbnailPrevButton.click(function (e) {
          if (!$(this).hasClass('inactive')) {
            _self.slideThumbnails(1);
          }
        });

        var sens = this.getOption('popupMode') ? 300 : 0;
        setTimeout(function(){
          _self.slideThumbnails(0);
        }, sens);

        this.thumbnailContainer.find('img').on('load', function(){
          _self.slideThumbnails(0);
        });
      }
    },
    slideThumbnails: function (direction) {
      if (this.initialized && this.mode !== 'desktop') {
        return;
      }

      var isHorizontal = this.getOption('thumbnailListType') === 'horizontal';
      var innerSize = isHorizontal ? this.thumbnailContainer.width() : this.thumbnailContainer.height();
      var contentSize = isHorizontal ? this.thumbnailList.width() : this.thumbnailList.height();
      var breakPoint = parseInt(this.getOption('thumbnailPagerBreakpoint'));
      if (!this.thumbnailContainer.hasClass(this.getOption('thumbnailListType')+'-layout')) {
        this.thumbnailContainer.addClass(this.getOption('thumbnailListType')+'-layout')
      }

      if (isNaN(breakPoint)) {
        breakPoint = 0;
      }

      if (innerSize >= contentSize || (breakPoint && this.windowWidth <= breakPoint)) {
        this.thumbnailNextButton.addClass('inactive');
        this.thumbnailPrevButton.addClass('inactive');
        this.thumbnailContainer.addClass('overflow-available');
      } else {
        var currentSlide = parseInt(this.thumbnailList.data('slide-value'));
        var boxSize = this.getThumbnailBoxSize(isHorizontal);
        var stepSize = Math.floor(innerSize / boxSize) * boxSize;
        this.thumbnailContainer.removeClass('overflow-available');

        if (isNaN(currentSlide)) {
          currentSlide = 0;
        }

        currentSlide += direction * stepSize;

        if (currentSlide >= 0) {
          currentSlide = 0;
          this.thumbnailPrevButton.addClass('inactive');
        }

        if (currentSlide < 0) {
          this.thumbnailPrevButton.removeClass('inactive');
        }

        if (contentSize < Math.abs(currentSlide) + innerSize) {
          currentSlide = -1 * contentSize + innerSize;
          this.thumbnailNextButton.addClass('inactive');
        } else {
          this.thumbnailNextButton.removeClass('inactive');
        }

        var translateFn = isHorizontal ? 'X' : 'Y';
        this.thumbnailList.css({
          'transform': 'translate' + translateFn + '(' + currentSlide + 'px)'
        });

        this.thumbnailList.data('slide-value', currentSlide);
      }
    },
    removeThumbnailDOMEvents: function () {
      if (this.mode == 'mobile') {
        if (this.images) {
          this.thumbnailSlider.destroy();
        }
      } else {
        this.thumbnailList.find('.thumbnail-item').off('click');
        if (this.getOption('hasThumbnailPager')) {
          this.thumbnailNextButton.off('click');
          this.thumbnailPrevButton.off('click');

          this.thumbnailNextButton.remove();
          this.thumbnailPrevButton.remove();

          this.thumbnailNextButton = null;
          this.thumbnailPrevButton = null;
        }
      }
      this.thumbnailList.html('');
    },
    showPreview: function (index) {
      if (parseInt(index) != this.currentIndex) {
        var zoomEnabled = this.getOption('zoomEnabled');
        var img = typeof this.images[index] !== 'undefined' ? this.images[index] : undefined;
        if (img) {
          this.currentIndex = parseInt(index);

          if (typeof img.is_video !== 'undefined' && img.is_video) {
            this.currentIsVideo = true;
            if (zoomEnabled) {
              this.destoryPreviewZoom();
            }
            this.previewContainer.html('');
            this.previewContainer.append(this.getVideoContent(img));
          } else {
            this.currentIsVideo = false;
            var url = img.urls.big;
            var zoomAttribute = '';

            if (this.isRetina() && typeof img.retinaUrls !== 'undefined' && typeof img.retinaUrls.big !== 'undefined' && img.retinaUrls.big) {
              url = img.retinaUrls.big;
            }

            if (zoomEnabled) {
              zoomUrl = url;
              this.destoryPreviewZoom();

              if (typeof img.urls.zoom !== 'undefined' && img.urls.zoom) {
                zoomUrl = img.url.zoom
              }

              if (this.isRetina && typeof img.retinaUrls !== 'undefined' && typeof img.retinaUrls.zoom !== 'undefined' && img.retinaUrls.zoom) {
                zoomUrl = img.retinaUrls.zoom;
              }

              zoomAttribute = 'data-zoom-image="'+zoomUrl+'"';
            }


            this.previewContainer.html('<img src="' + url + '" alt="' + img.name + '" '+zoomAttribute+' />');
            if (zoomEnabled) {
              var _self = this;

              this.initPreviewZoom();
            }
          }
        }
      }
    },
    initPreviewZoom: function() {
      var img = this.previewContainer.find('img');
      if (img && img.length == 1) {
        var zoomParams = this.getOption('zoomOptions');
        img.ezPlus(zoomParams);
      }
    },
    destoryPreviewZoom: function() {
      $('.zoomContainer').remove();
      var img = this.previewContainer.find('img');
      if (img && img.length === 1) {
        img.removeData('elevateZoom');
      }
    },
    openGallery: function () {
      if (this.mode !== 'mobile' && this.getOption('galleryWindowAvailable') && !this.currentIsVideo) {
        if (!this.galleryWindow) {
          this.galleryWindow = new ProductGalleryWindow(this);
        }
        this.destoryPreviewZoom();
        this.stopVideos(this.previewContainer);
        this.galleryWindow.open();
      }
    },
    closeGallery: function () {
      if (this.galleryWindow) {
        this.galleryWindow.close();
      }
    },
    isRetina: function () {
      return this.pixelRatio > 1;
    },
    checkThumbnailSlider: function () {
      var breakpoint = this.getOption('breakpoint');
      var slider = breakpoint && parseInt(breakpoint) >= this.windowWidth;
      var mode = slider ? 'mobile' : 'desktop';

      if (mode != this.mode) {
        this.createThumbnailList(slider);
        this.mode = mode;
      }
    },
    createThumbnailList: function (slider) {
      if (this.initialized) {
        this.removeThumbnailDOMEvents();
      }

      var desktopLimit = parseInt(this.getOption('thumbnailDesktopLimit'));
      var imageCount = this.images.length;
      var limit = !slider && desktopLimit > 0 && desktopLimit <= imageCount ? desktopLimit : imageCount;
      var moreCounter = 0;

      if (!slider && limit < imageCount) {
        moreCounter = imageCount - limit;
      }

      for (var i = 0; i < limit; i++) {
        var img = this.images[i];

        var item = $('<li>', {
          class: 'thumbnail-item display-flex swiper-slide justify-center align-items-center cursor-pointer position-relative' + (i == 0 && !slider ? ' active' : ''),
          'data-index': i,
        });

        if (moreCounter && i == (limit - 1)) {
          item.addClass('more-label-item');
          item.attr('data-counter', moreCounter);
        }

        if (typeof img.is_video !== 'undefined' && img.is_video) {
          if (slider) {
            item.append(this.getVideoContent(img));
          } else {
            var poster = img.urls.small;
            if (this.isRetina() && typeof img.retinaUrls !== 'undefined' && typeof img.retinaUrls.small !== 'undefined' && img.retinaUrls.small) {
              poster = img.retinaUrls.small;
            }

            if (poster) {
              item.append('<img ' + (!this.initialized ? 'class="lazy" data-' : '') + 'src="' + poster + '" alt="' + img.name + '" />');
            }

            item.append('<span class="play-button display-flex justify-center align-items-center cursor-pointer position-absolute absolute-center">' + svg('play-icon') + '</span>');
          }
        } else {
          var src = typeof img.urls.small !== 'undefined' && !slider ? img.urls.small : img.urls.big;
          if (this.isRetina() && typeof img.retinaUrls !== 'undefined') {
            if (!slider && typeof img.retinaUrls.small !== 'undefined' && img.retinaUrls.small) {
              src = img.retinaUrls.small;
            } else if (typeof img.retinaUrls.big !== 'undefined' && img.retinaUrls.big) {
              src = img.retinaUrls.big;
            }
          }
          item.append('<img ' + (!this.initialized ? 'class="lazy" data-' : '') + 'src="' + src + '" alt="' + img.name + '"  />');
        }

        this.thumbnailList.append(item);
      }

      if (!slider) {
        if (this.getOption('hasThumbnailPager')) {
          this.createThumbnailPager();
        }

        if (this.thumbnailList.hasClass('swiper-wrapper')) {
          this.thumbnailList.removeClass('swiper-wrapper');
          this.container.removeClass('mobile-slider-initialized');
        }

        this.addThumbnailDOMEvents();
      } else {
        var _self = this;

        if (!this.thumbnailList.hasClass('swiper-wrapper')) {
          this.thumbnailList.addClass('swiper-wrapper');
          this.container.addClass('mobile-slider-initialized');
        }

        var sens = !this.initialized && this.getOption('popupMode') ? 300 : 0;
        setTimeout(function(){
          _self.thumbnailSlider = new Swiper('.thumbnail-container-'+_self.generatedId+'.mobile-thumbnail-slider', {
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            }
          });

          _self.thumbnailSlider.on('transitionEnd', function () {
            _self.stopVideos(_self.thumbnailContainer);
          });
        }, sens);
      }
    },
    createThumbnailPager: function () {
      this.thumbnailPrevButton = $('<a>', {
        'href': 'javascript:void(0)',
        'html': svg(this.getOption('thumbnailPagerButtonIcon')),
        'class': 'thumbnail-pager position-absolute display-flex justify-center align-items-center prev-pager'
      });

      this.thumbnailNextButton = $('<a>', {
        'href': 'javascript:void(0)',
        'html': svg(this.getOption('thumbnailPagerButtonIcon')),
        'class': 'thumbnail-pager position-absolute display-flex justify-center align-items-center next-pager'
      });

      this.thumbnailContainer.append(this.thumbnailPrevButton);
      this.thumbnailContainer.append(this.thumbnailNextButton);
    },
    getThumbnailBoxSize: function(isHorizontal) {
      var item = this.thumbnailList.find('.thumbnail-item').first();
      return isHorizontal ? item.outerWidth(true) : item.outerHeight(true);
    },
    setParams: function (params) {
      if (params.images) this.setImages(params.images);
      if (params.options) this.setOptions(params.options);
    },
    setImages: function (images) {
      if (Array.isArray(images)) {
        this.images = images;
      }
    },
    setOptions: function (options) {
      this.options = $.extend(true, {}, this.options, options);
    },
    getVideoContent: function (img) {
      if (typeof img.videoUrls !== 'undefined' && Array.isArray(img.videoUrls)) {
        var poster = img.urls.big;
        if (this.isRetina() && typeof img.retinaUrls !== 'undefined' && typeof img.retinaUrls.big !== 'undefined' && img.retinaUrls.big) {
          poster = img.retinaUrls.big;
        }

        var params = this.getOption('videoOptions');

        if (poster) {
          params.poster = poster;
        }

        var video = $('<video>', params);

        for (var i = 0; i < img.videoUrls.length; i++) {
          video.append('<source src="' + img.videoUrls[i].src + '" type="' + img.videoUrls[i].type + '" />');
        }

        return video;
      } else {
        console.error('videoUrls is missing!');
      }

      return '';
    },
    getOption: function (key) {
      return this.getObjectValue('options', key);
    },
    getSelector: function (key) {
      return this.getObjectValue('selectors', key);
    },
    getObjectValue: function (object, key) {
      if (typeof this[object] === 'undefined') {
        throw 'Undefined property ' + object + '!'
      }

      if (typeof this[object][key] === 'undefined') {
        throw 'Undefined ' + object + ' key: ' + key + '!'
      }

      return this[object][key];
    },
    stopVideos: function (container) {
      container.find('video').each(function () {
        $(this).trigger('pause');
      });
    },
    showLog: function(message) {
      if (this.getOption('logMode')) {
        console.log(message);
      }
    }
  };

  wnd.ProductGallery = ProductGallery;
})(window);
