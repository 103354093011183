require('./inc/site.js');
require('./inc/ItemSlider.js');

var mobileSwipers = undefined;
var mobileSwiperInitialized = false;
var mobileSwiperBreakpoint = 767;

$(document).ready(function(){
  $('[data-purpose="item-slider"]').each(function(){
    new ItemSlider($(this));
  });

  new Swiper('.header-slider', {
    height:'auto',
    pagination: {
      el:'.swiper-pagination',
      clickable: true
    }
  });

  $(document).on(PROJECT_NAMESPACE+'.window_width_changed', function(e, newWidth){
    if (newWidth <= mobileSwiperBreakpoint && !mobileSwiperInitialized) {
      initializeMobileSwiper();
    } else if (newWidth > mobileSwiperBreakpoint && mobileSwiperInitialized && mobileSwipers) {
      mobileSwipers.destroy();
      mobileSwiperInitialized = false;
    }
  });

  if (window.innerWidth <= mobileSwiperBreakpoint) {
    initializeMobileSwiper();
  }
});

function initializeMobileSwiper() {
  if (!mobileSwiperInitialized) {
    mobileSwipers = new Swiper('.swiper-container.mobile-swiper-container', {
      height:'auto',
      autoplay: {
        delay:4000
      },
    });

    mobileSwiperInitialized = true;
  }
}
