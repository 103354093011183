(function(wnd){
  function SiteHelper(params) {
    try {
      if (params) {
        this.setParams(params);
      }
    } catch (error) {

    }
  }

  SiteHelper.prototype = {
    numberFormatPattern: {
      thousandSeparator:'.',
      decimalSeparator:',',
      decimals:0
    },
    numberFormat: function(number, suffix, thousandSeparator, decimalSeparator, decimals) {
      if (!thousandSeparator) {
        thousandSeparator = this.numberFormatPattern.thousandSeparator;
      }

      if (!decimalSeparator) {
        decimalSeparator = this.numberFormatPattern.decimalSeparator;
      }

      if (!decimals) {
        decimals = this.numberFormatPattern.decimals;
      }

      number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
      var n = !isFinite(+ number) ? 0 : + number;
      var prec = !isFinite(+ 2) ? 0 : Math.abs(2);
      var s = '';

      toFixedFix = function(n, prec) {
          var k = Math.pow(10, prec);
          return '' + (
          Math.round(n * k) / k).toFixed(prec);
      };

      s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');

      var formatted = s;

      if (formatted[0].length > 3) {
        formatted[0] = formatted[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandSeparator);
      }

      if ((formatted[1] || '').length < prec) {
        formatted[1] = formatted[1] || '';
        formatted[1] += new Array(prec - formatted[1].length + 1).join('0');
      }

      formatted = decimals ? formatted.join(decimalSeparator) : formatted[0];

      return formatted+(suffix ? " "+suffix : "");
    },
    setParams: function(params) {
      if (params.numberFormatPattern) this.setNumberFormatPattern(params.numberFormatPattern);
    },
    setNumberFormatPattern: function(pattern) {
      this.numberFormatPattern = $.extend({}, this.numberFormatPattern, pattern);
    },
    unionArray: function(a, b) {
      return a.filter(function(item, index) {
        return b.indexOf(item) >= 0;
      });
    }
  }

  wnd.SiteHelper = SiteHelper;
})(window);
