require('./TimeCounter.js');
require('./MessageHandler.js');
require('./SiteEventHandler.js');
require('./MobileMenu.js');
require('./FormHandler.js');
require('./SiteHelper.js');
require('./core/remodal.js');
require('./core/accordion.js');
require('./core/tooltipster.js');
require('./core/swiper.js');
require('./ProductGallery.js');

var pwWindowWidth = window.innerWidth;
var header = undefined;
var headerBorder = undefined;
var siteHelperObj = undefined;
var scrollToTopBtn = undefined;
var siteRatingModal = undefined;

$(document).ready(function(){
  var resizeFn = function() {
    if (window.innerWidth != pwWindowWidth) {
      pwWindowWidth = window.innerWidth;
      $(document).trigger(PROJECT_NAMESPACE+'.window_width_changed', pwWindowWidth);
    }
  };

  window.addEventListener('resize', resizeFn);

  /** fix header beállítás */
  header = $('.main-header');
  scrollToTopBtn = $('[data-purpose="scroll-to-top-button"]');
  var hasHeader = false;
  var hasScrollTopBtn = false;
  if (scrollToTopBtn && scrollToTopBtn.length > 0) {
    hasScrollTopBtn = true;
    scrollToTopBtn.on('click', function(){
      $('html, body').animate({
        scrollTop: 0
      }, 400);
    });
  }

  if (header && header.length > 0) {
    headerBorder = $('[data-purpose="header-scroll-border"]');
    hasHeader = true;

    if (headerBorder.length === 0) {
      headerBorder = header;
    }
  }

  if (hasHeader || hasScrollTopBtn) {
    var siteScrollFn = function() {
      var scrollTop = $(window).scrollTop();
      if (hasHeader) {
        var height = headerBorder.outerHeight(true) + headerBorder.offset().top;

        if (height <= scrollTop && !header.hasClass('opened-fix-menu')) {
          header.addClass('opened-fix-menu');
          $('body').addClass('opened-fix-menu');
          $('.search-box.opened').removeClass('opened');
          $(document).trigger(PROJECT_NAMESPACE+'.header_position_changed');
        } else if (height > scrollTop && header.hasClass('opened-fix-menu')) {
          header.removeClass('opened-fix-menu');
          $(document).trigger(PROJECT_NAMESPACE+'.header_position_changed');
          $('body').removeClass('opened-fix-menu');
          $('.search-box.opened').removeClass('opened');

          $('.main-header').find('.submenu').hide(0, function() {
            var submenu = $(this);
            setTimeout(function(){
              submenu.removeAttr('style');
            }, 250);
          });
        }
      }

      if (hasScrollTopBtn) {
        if (scrollTop > 200 && !scrollToTopBtn.hasClass('active')) {
          scrollToTopBtn.addClass('active');
        } else if (scrollTop <= 200 && scrollToTopBtn.hasClass('active')) {
          scrollToTopBtn.removeClass('active');
        }
      }
    };

    window.addEventListener('touchend', siteScrollFn);
    window.addEventListener('touchmove', siteScrollFn);
    window.addEventListener('scroll', siteScrollFn);
  }

  /** Termék kártya mobil nyitás */
  $('.product-card .mobile-cart-btn').on('click', function(){
    $(this).toggleClass('active');
    $(this).parent().find('.cart-content').slideToggle();
  });

  /** Szín info megjelenítése */
  $('.color-variant-label').hover(function(){
    var color = $(this).data('label');
    if (!color) {
      color = '';
    }
    $(this).parents('.row').find('[data-purpose="color-info"]').html(color);
  }, function(){
    var color = '';

    var input = $(this).parents('.row').find('input[type="radio"]:checked');
    if (input && input.length === 1) {
      label = $('label[for="'+input.attr('id')+'"]');
      if (label && label.length === 1) {
        color = label.data('label');
        if (!color) {
          color = '';
        }
      }
    }

    $(this).parents('.row').find('[data-purpose="color-info"]').html(color);
  });

  $('.image-item-row').find('input[type="radio"]').change(function(){
    var label = $('label[for="'+$(this).attr('id')+'"]');
    var color = ';'
    if (label && label.length === 1) {
      color = label.data('label');
      if (!color) {
        color = '';
      }
    }

    $(this).parents('.row').find('[data-purpose="color-info"]').html(color);
  });

  $('[data-purpose="mobile-search-button"]').on("click", function(){
    header.find('.search-box').toggleClass('opened');
  });


  $('#gyorkeresoMezo').focus(function(){
    $('html, body').addClass('overflow-hidden');
  });

  $('#gyorkeresoMezo').blur(function(){
    $('html, body').removeClass('overflow-hidden');
  });

  siteRatingModal = $('[data-purpose="site-rating-modal"]');
  if (siteRatingModal && siteRatingModal.length > 0) {
    var siteRatingBox = siteRatingModal.find('[data-purpose="site-rating-container"]');
    var siteRatingOpenBtn = siteRatingModal.find('[data-purpose="site-rating-button"]');

    if (siteRatingOpenBtn.length > 0 && siteRatingBox.length > 0) {
      siteRatingOpenBtn.on('click', function(){
        siteRatingBox.toggleClass('opened');
        if (siteRatingBox.hasClass('rated')) {
          siteRatingModal.fadeOut(300, function(){
            $(this).remove();
          });
        }
      });

      siteRatingBox.find('.close-btn').click(function(){
        siteRatingBox.removeClass('opened');
        if (siteRatingBox.hasClass('rated')) {
          siteRatingModal.fadeOut(300, function(){
            $(this).remove();
          });
        }
      });
    }
  }

  /** promociós banner visszaszámláló */
  initTimeCounters();

  /** messageHandler */
  initMessageHandler();

  /** SiteEventHandler */
  initSiteEventHandler();

  /** Mobil menü */
  initMobileMenu();

  /** SiteHelper */
  initSiteHelper();

  /** Form handler */
  initFormHandlers();

  /** Accordion */
  initAccordionLists();

  /** QuantityControlPanels */
  initQuantityControlPanels();

  /** Tooltipster */
  initTooltipster();

  /** Mailto linkek kezelése */
  initMailtoLinks();

  /** Egymástól függő tartalmak */
  initDependsOnRows();
});

function initTimeCounters() {
  /**
   * visszaszámláló
   */
  var counterSection = $.find('[data-purpose="counter-box"]:not(.initialized)');
  if (counterSection && counterSection.length > 0) {
    $(counterSection).each(function(){
      var params = {
        remainingTime: {
          days:0,
          hours:0,
          minutes:0,
          seconds:0,
        }
      };
      var times = $(this).find('.counter-item');

      if (times && times.length > 0) {
        params.hasCounter = true;

        times.each(function(){
          var type = $(this).data('type')
          var val = $(this).data('init-value');

          params.remainingTime[type] = parseInt(val);
        });

        var total = 0;
        $.each(params.remainingTime, function(key, value){
          var multiplier = 1;

          switch (key) {
            case 'days':
                multiplier = 86400;
                break;
            case 'hours':
                multiplier = 3600;
                break;
            case 'minutes':
                multiplier = 60;
                break;
          }

          total += value * multiplier;
        });

        params.total = total;

        new TimeCounter($(this), params);
      }
    });
  }
}

function initMessageHandler()
{
  if (typeof MessageHandler !== 'undefined') {
    var params = {};

    if (typeof messageHandlerParams !== 'undefined') {
      params = $.extend({}, params, messageHandlerParams);
    }

    new MessageHandler(params);
  }
}

function initSiteEventHandler() {
  if (typeof SiteEventHandler !== 'undefined') {
    var params = {};

    if (typeof eventHandlerParams !== 'undefined') {
      params = $.extend({}, params, eventHandlerParams);
    }

    new SiteEventHandler(params);
  }
}

function initMobileMenu()
{
  if (typeof MobileMenu !== 'undefined') {
    var params = {
      createContent: false,
      type:'slide'
    };

    if (typeof mobileMenuParams === 'object') {
      params = $.extend({}, params, mobileMenuParams);
    }

    new MobileMenu($('.mobile-menu-btn'), params);
  }
}

function initSiteHelper()
{
  if (typeof SiteHelper !== 'undefined') {
    var params = {};

    if (typeof siteHelperParams === 'object') {
      params = $.extend({}, params, siteHelperParams);
    }

    window.siteHelperObj = new SiteHelper(params);
  }
}

function initFormHandlers()
{
  $('[data-purpose="general-form"]:not(.initialized)').each(function(){
    var options = $(this).data('options');

    new FormHandler($(this), options);
  });
}

function initAccordionLists()
{
  $('.accordion-list:not(.initialized)').each(function(ind, el) {
		var options = {};
		var th = $(el);

		if (th.data('single-active-item') != undefined) options.singleActiveItem = false;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
    if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));

		new AccordionList(th, options);
  });
}

function initQuantityControlPanels()
{
  $('[data-purpose="quantity-change-button"]:not(.initialized)').each(function(){
    $(this).click(function(e) {
      if ($(this).hasClass('inactive')) {
        e.preventDefault();
        return false;
      }

      var parent = $(this).parents('[data-purpose="quantity-control-panel"]');

      if (parent) {
        var field = parent.find('[data-purpose="quantity"]');
        if (field) {
          var value = parseInt(field.val()) + parseInt($(this).data('value'));
          if (isNaN(value)) {
            value = 1;
          }

          field.val(value);
          field.change();

          var minus = parent.find('[data-purpose="quantity-change-button"][data-type="minus"]');
          if (minus) {
            var inactiveValue = parent.hasClass('cart-panel') ? 0 : 1;
            if (value == inactiveValue) {
              minus.addClass('inactive');
            } else if (value > inactiveValue && minus.hasClass('inactive')) {
              minus.removeClass('inactive');
            }
          }
        }
      }
    });

    $(this).addClass('initialized');
  });
}

function initTooltipster()
{
  if (typeof $.tooltipster !== 'undefined') {
    $.fn.tooltipster('setDefaults', {
      position: 'top'
    });

    $('.tooltip:not(.initialized)').each(function(){
      $(this).addClass('initialized');
      $(this).tooltipster({
        contentAsHTML:true,
      });
    });
  }
}

function openRemodalContent(id)
{
  var item = $('[data-remodal-id="'+id+'"]');
  if (item && item.length === 1) {
    item.remodal().open();
  }
}

function initMailtoLinks()
{
  $('.mailtolink:not(.initialized)').each(function(){
    $(this).on('click', function(){
      handleMailToLinkClick($(this));
    });
    $(this).addClass('initialized');
  });
}

function handleMailToLinkClick(item) {
  var href = item.attr('href');
  if (href == 'javascript:void(0)') {
    var hidden = item.find('.hidden-mail');
    if (hidden && hidden.length === 1) {
      item.attr('href', 'mailto:'+hidden.data('user')+'@'+hidden.data('domain'));
    }
  }
}

function svg(icon) {
  return '<svg class="icon '+icon+'"><use xlink:href="#'+icon+'" /></svg>';
}

function initDependsOnRows()
{
  $('.depends-on-item').each(function(){
    $(this).on('change', function(){
      var form = $(this).parents('form');
      if (form) {
        checkDependsOnRows(form);
      }
    }).change();
  });
}

function checkDependsOnRows(form)
{
  if (form && form.length > 0) {
    form.find('[data-purpose="depends-on-row"]').each(function(){
      var expectValue = $(this).data('expect-value');
      var visible = false;
      if (expectValue) {
        var fields = expectValue.split('|');
        visible = true;
        for (var i = 0; i < fields.length; i++) {
          var tmp = fields[i].split(':');

          if (tmp.length == 2) {
            var fieldName = tmp[0];
            var values = tmp[1].split(',');

            var field = form.find('[name="'+fieldName+'"]');
            var value = field && field.length > 0 ? field.val() : '';
            if (field.is(':radio')) {
              value = '';
              $.each(field, function(){
                if ($(this).prop('checked')) {
                  value = $(this).val();
                }
              });

              if (values.indexOf(value) < 0) {
                visible = false;
              }
            } else if (field.is(':checkbox')) {
              var checkedValues = [];
              field.each(function(){
                if ($(this).prop('checked')) {
                  checkedValues.push($(this).val());
                }
              });

              var union = window.siteHelperObj.unionArray(values, checkedValues);
              if (union.length === 0) {
                visible = false;
              }
            } else if (values.indexOf(value) < 0) {
              visible = false;
            }
          } else {
            visible = false;
          }

          if (!visible) {
            break;
          }
        }
      }

      if ((visible && !$(this).hasClass('active')) || (!visible && $(this).hasClass('active'))) {
        $(this).toggleClass('active');
      }
    });
  }
}

window.initAccordionLists = initAccordionLists;
window.initQuantityControlPanels = initQuantityControlPanels;
window.openRemodalContent = openRemodalContent;
window.initTooltipster = initTooltipster;
window.initMailtoLinks = initMailtoLinks;
window.initDependsOnRows = initDependsOnRows;
window.svg = svg;
