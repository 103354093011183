(function(wnd) {
  function TimeCounter(container, params) {
      try {
          if (!container || (container && container.length == 0)) {
              throw 'Container is required!';
          }

          this.container = container;
          if (params) {
              this.setParams(params);
          }

          this.init();
      } catch (error) {
        console.error(error);
      }
  }

  TimeCounter.prototype = {
      container: undefined,
      hasCounter: false,
      countdownContainer: undefined,
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      total: undefined,
      remainingTime: {
          days:0,
          hours:0,
          minutes:0,
          seconds:0,
      },
      countdownInterval: undefined,
      hasAnimation: false,
      closeBtn: undefined,
      closed: false,
      init: function() {
          this.setElements();
          this.initCountdown();
          this.addDOMEvents();

          this.container.addClass('initialized');
      },
      addDOMEvents: function() {
          var _self = this;

          if (this.closeBtn && this.closeBtn.length > 0) {
              this.closeBtn.click(function(){
                  _self.closeCounterSection();
              });
          }
      },
      setElements: function() {
          this.closeBtn = this.container.find('[data-purpose="counter-close"]');
          this.countdownContainer = this.container.find('[data-purpose="counter-container"]');
          if (this.countdownContainer && this.countdownContainer.length > 0) {
              this.hasCounter = true;
              this.days = this.countdownContainer.find('.counter-item.days .figure');
              this.hours = this.countdownContainer.find('.counter-item.hours .figure');
              this.minutes = this.countdownContainer.find('.counter-item.minutes .figure');
              this.seconds = this.countdownContainer.find('.counter-item.seconds .figure');
          }
      },
      setParams: function(params) {
          if (params.remainingTime) this.setRemainingTime(params.remainingTime);
          if (params.total) this.setTotal(params.total);
      },
      setRemainingTime: function(remainingTime) {
          this.remainingTime = $.extend({}, this.remainingTime = remainingTime);
      },
      setTotal: function(total) {
          this.total = total;
      },
      initCountdown: function() {
          if (this.hasCounter) {
              var _self = this;
              var day_1 = undefined;
              var day_2 = undefined;
              var hour_1 = this.hours.eq(0);
              var hour_2 = this.hours.eq(1);
              var minutes_1 = this.minutes.eq(0);
              var minutes_2 = this.minutes.eq(1);
              var seconds_1 = this.seconds.eq(0);
              var seconds_2 = this.seconds.eq(1);

              if (this.days && this.days.length > 0) {
                  day_1 = this.days.eq(0);
                  day_2 = this.days.eq(1);
              }

              this.countdownInterval = setInterval(function(){
                  if (_self.total > 0) {
                      --_self.remainingTime.seconds;

                      if (_self.remainingTime.minutes >= 0 && _self.remainingTime.seconds < 0) {
                          _self.remainingTime.seconds = 59;
                          --_self.remainingTime.minutes;
                      }

                      if (_self.remainingTime.hours >= 0 && _self.remainingTime.minutes < 0) {
                          _self.remainingTime.minutes = 59;
                          --_self.remainingTime.hours;
                      }

                      if (_self.remainingTime.days >= 0 && _self.remainingTime.hours < 0) {
                          _self.remainingTime.hours = 23;
                          --_self.remainingTime.days;
                      }

                      if (day_1 && day_2) {
                          _self.checkTime(_self.remainingTime.days, day_1, day_2)
                      }

                      _self.checkTime(_self.remainingTime.hours, hour_1, hour_2);

                      _self.checkTime(_self.remainingTime.minutes, minutes_1, minutes_2);

                      _self.checkTime(_self.remainingTime.seconds, seconds_1, seconds_2);

                      --_self.total;
                  } else {
                      clearInterval(_self.countdownInterval);
                  }
              }, 1000);
          }
      },
      checkTime: function(value, item1, item2) {
          var val_1 = value.toString().charAt(0);
          var val_2 = value.toString().charAt(1);
          var fig_1 = item1.find('.top').html();
          var fig_2 = item2.find('.top').html();

          if (value >= 10) {
              if (fig_1 !== val_1) {
                  this.animateFigure(item1, val_1);
              }

              if (fig_2 !== val_2) {
                  this.animateFigure(item2, val_2);
              }
          } else {
              if (fig_1 !== '0') {
                  this.animateFigure(item1, '0');
              }
              if (fig_2 !== val_1) {
                  this.animateFigure(item2, val_1);
              }
          }
      },
      animateFigure: function(item, value) {
        item.html('<span>'+value+'</span>');
      },
      closeCounterSection: function() {
        this.container.slideUp();
        $(document).trigger(PROJECT_NAMESPACE+'.close_counter_section', this.container.data('id'));
      }
  };

  wnd.TimeCounter = TimeCounter;
})(window)
