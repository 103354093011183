(function (wnd) {
  function SwipeHandler(element) {
    try {
      if (!element) {
        throw 'Element is required!';
      }

      this.element = element;
    } catch (error) {
      console.error(error);
    }
  }

  SwipeHandler.prototype = {
    element: undefined,
    posX: null,
    posY: null,
    sens:20,
    initialized: false,
    init: function () {
      if (!this.initialized) {
        this.initialized = true;
        this.xDown = null;
        this.yDown = null;

        this.addDOMEvents();
      }
    },
    destroy: function() {
      if (this.initialized) {
        this.element.off('touchstart touchend');
        this.initialized = false;
      }
    },
    addDOMEvents: function () {
      var _self = this;
      if (this.element && this.element.length > 0) {
        this.element.on('touchstart', function(e){
          _self.touchFunction(e, 'start');
        });

        this.element.on('touchend', function(e){
          _self.touchFunction(e, 'end');
        });
      }
    },
    touchFunction: function(evt, type) {
      var event = typeof evt.originalEvent != 'undefined' ? evt.originalEvent : evt.changedTouches;
      var origEvent = typeof event.originalEvent !== 'undefined' ? event.originalEvent : event.changedTouches;

      if (origEvent && typeof origEvent[0] !== 'undefined') {
        var posX = origEvent[0].pageX;
        var posY = origEvent[0].pageY;

        switch (type) {
          case 'start':
            this.setPosX(posX);
            this.setPosY(posY);
            break;
          case 'end':
            var xDiff = this.posX - posX;
            var yDiff = this.posY - posY;

            if (Math.abs(xDiff) > Math.abs(yDiff)) {
              var valid = this.checkSens(xDiff);
              if (valid) {
                if (xDiff > 0) {
                  if (typeof this.onLeft === 'function') {
                    this.onLeft.call();
                  }
                } else if (typeof this.onRight === 'function') {
                  this.onRight.call();
                }
              }
            } else {
              var valid = this.checkSens(yDiff);
              if (valid) {
                if (yDiff > 0) {
                  if (typeof this.onUp === 'function') {
                    this.onUp.call();
                  }
                } else if (typeof this.onDown === 'function') {
                  this.onDown.call();
                }
              }
            }

            this.setPosX(null);
            this.setPosY(null);
            break;
        }
      }
    },
    checkSens: function(value) {
      return Math.abs(value) >= this.sens;
    },
    setPosX: function(x) {
      this.posX = x;
    },
    setPosY: function(y) {
      this.posY = y;
    },
    onLeft: function (callback) {
      this.onLeft = callback;

      return this;
    },
    onRight: function (callback) {
      this.onRight = callback;

      return this;
    },
    onUp: function (callback) {
      this.onUp = callback;

      return this;
    },
    onDown: function (callback) {
      this.onDown = callback;

      return this;
    }
  }

  wnd.SwipeHandler = SwipeHandler;
})(window);
